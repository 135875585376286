import { IPlaylistItemSanitized, IPlaylistSanitized } from '~/interfaces/playlists';
import { displayLoaderMessage, hideLoader, hideLoaderMessage } from '~/controllers/loader';
import { setLog, setStorageLog } from '~/controllers/logs';

import { PLAYLIST_DEFAULTS } from '~/config';
import { getPlaylist } from '~/services/playlists';
import { clearCache, toggleElement } from '~/utils';
import { log, LogType } from './logs';
import { isEmpty, equals } from 'ramda';
import { storeClientName } from '../services/registration';

declare global {
  interface Window {
    _paq: any;
  }
}

export interface IPlayer {
  init: () => Promise<void>;
  checkForPlaylistUpdates: () => void;
  renderAsset: (overrideAsset?: IPlaylistItemSanitized) => void;
  renderImage: (item: IPlaylistItemSanitized) => void;
  renderVideo: (item: IPlaylistItemSanitized) => void;
  resetPlayer: () => void;
  pause: () => void;
  play: () => void;
}

export default class Player implements IPlayer {
  imageElement: HTMLImageElement;
  videoElement: HTMLVideoElement;
  iframeElement: HTMLIFrameElement | null;
  playerElement: HTMLDivElement;
  videoSource: HTMLElement;
  playlist: IPlaylistSanitized;
  refPlaylist: IPlaylistSanitized | any;
  overrideAsset: IPlaylistItemSanitized;
  updatedPlaylist: IPlaylistSanitized | any;
  currentIndex: number;
  playerStatus: string;
  playerTimesPlayed: number;
  _paq: any;
  imageTimeout: NodeJS.Timeout;
  playlistUpdateInterval: NodeJS.Timeout;

  constructor() {
    this.imageElement = document.querySelector('.dsp-player__image');
    this.videoElement = document.querySelector('.dsp-player__video');
    this.iframeElement = null;
    this.playerElement = document.querySelector('.dsp-player');
    this.videoSource = document.querySelector('.dsp-player__video source');
    this.currentIndex = 0;
    this.playlist = null;
    this.refPlaylist = null;
    this.overrideAsset = null;
    this.imageTimeout = null;
    this.playerTimesPlayed = 0;
    this._paq = window._paq = window._paq || [];
    this.playerStatus = 'inactive';

    setLog('playerStatus', this.playerStatus);
    setLog('playerTimesPlayed', this.playerTimesPlayed);
  }

  init = async (): Promise<void> => {
    if (!this.videoElement || !this.imageElement) {
      return;
    }

    this.refPlaylist = await getPlaylist();

    if(!this.refPlaylist){
      log(' >> DEVICE HAS NO PLAYLISTS');
      displayLoaderMessage('There is no playlist neither a country fallback playlist assigned to this device! \n Please go to the Retail Content Manager and select a playlist.');
    }

    this.playlist = { ...this.refPlaylist };

    storeClientName(this.refPlaylist.brand);

    this.trackPageView();

    if (isEmpty(this.refPlaylist.items)) {
      log(' >> PLAYLISTS HAVE NO ITEMS');
      displayLoaderMessage('Assigned playlists / country fallback playlist do not contain displayable media! \n Please go to the Retail Content Manager and adjust the playlist.');
    } else {
      hideLoaderMessage();
      this.renderAsset();
    }
    this.setMultipleAssetsBlobsWithProgressXHR(this.refPlaylist);
    this.checkForPlaylistUpdates();
  };

  trackPageView = () => {
    if (this.refPlaylist) {
      this._paq.push([
        'trackPageView',
        `Device ID: ${localStorage.getItem('$log_deviceID')}; Playlist: ${this.refPlaylist.title}`
      ]);
    } else {
      this._paq.push([
        'trackPageView',
        `Device ID: ${localStorage.getItem('$log_deviceID')}; Playlist: No playlist`
      ]);
    }
  };

  checkExpiredAsset = item => {
    const today = new Date();
    const expirationDate = new Date(item.usageTo);
    if (expirationDate < today) {
      return false;
    } else {
      return true;
    }
  };

  checkForPlaylistUpdates = (): void => {
    const updateData = async (): Promise<void> => {
      this.updatedPlaylist = await getPlaylist();

      const hashedRef = this.refPlaylist.items.map(item => ({ id: item.id, timer: item.timer}))
      const hashedUpdated = this.updatedPlaylist.items.map(item => ({ id: item.id, timer: item.timer}))

      if (
        this.refPlaylist &&
        this.refPlaylist.items &&
        this.updatedPlaylist &&
        this.updatedPlaylist.items &&
        !equals(hashedRef, hashedUpdated)
      ) {
        log(' >> PLAYLISTS ARE DIFFERENT, SWITCHING PLAYLIST');
        this.refPlaylist = { ...this.updatedPlaylist };

        await this.setMultipleAssetsBlobsWithProgressXHR(this.refPlaylist);
        clearCache();
        this.resetPlayer();
      } else {
        log(' >> PLAYLISTS ARE SAME. NO ACTION NEEDED');
        log(' >> CHECK EXPIERED ASSETS IN CURRENT PLAYLIST');
        this.refPlaylist.items.filter(item => !item.usageTo || this.checkExpiredAsset(item));
      }
    };

    this.playlistUpdateInterval = setInterval(() => {
      log(' >> HEARTBEAT: Playlist refresh');
      updateData();
    }, PLAYLIST_DEFAULTS.UPDATE_INTERVAL);
  };

  downloadAssetWithProgressXHR = (url) =>{
   log(` >> START DOWNLOADING ${url}`);

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onprogress = function(event) {
          if (event.lengthComputable) {
              const percentComplete = (event.loaded / event.total) * 100;
              log(` >> DOWNLOADING progress ${url}: ${percentComplete.toFixed(2)}%`);
          } else {
              log(` >> Unable to compute download progress for ${url}`, LogType.Error);
          }
        };

        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 300) {
              log(` >> FINISH DOWNLOADING ${url}`);

                resolve(xhr.response);
            } else {
                reject(new Error(` >> Failed to download asset ${url}: ${xhr.statusText}`));
                log(` >> Failed to download asset`, LogType.Error);
            }
        };

        xhr.onerror = function() {
            reject(new Error(` >> Network error occurred while downloading asset. ${url}`));
            log(` >> Network error occurred while downloading asset. ${url}`, LogType.Error);
        };

        xhr.send();
    });
}

setMultipleAssetsBlobsWithProgressXHR = async (playlist) =>{
  try {
      this.playlist.items.forEach(item => URL.revokeObjectURL(item.blob));// Cleanup after loading

      const assetBlobs = await Promise.all(
        playlist.items.map((item) => 
              this.downloadAssetWithProgressXHR(item.source)
          )
      );
      this.playlist = { ...playlist };

      const assetURLs = assetBlobs.map(blob => URL.createObjectURL(blob));
      this.playlist.items.forEach((asset, index) => {
        asset.blob = assetURLs[index];
      });
  } catch (error) {
      log(` >> Error downloading assets:. ${error}`, LogType.Error);
  }
}

  /**
   *
   * @param overrideAsset to be used for overriding
   */
  renderAsset = (overrideAsset?: IPlaylistItemSanitized): void => {
    let currentItem;

    if (overrideAsset) {
      this.overrideAsset = overrideAsset;
      currentItem = overrideAsset;

      if (this.imageTimeout) {
        clearTimeout(this.imageTimeout);
      }

      log(` >> Playing override ${currentItem.type}`);

      const assetType = {
        image: (): void => this.renderImage(currentItem),
        video: (): void => this.renderVideo(currentItem),
        iframe: (): void => this.renderWebContent(currentItem)
      };
  
      assetType[currentItem.type] && assetType[currentItem.type]();
    } else {
      const { items } = this.playlist;

      currentItem = items[this.currentIndex];

      if (!this.playlist) {
        log(' >> DEVICE HAS NO PLAYLISTS');
        displayLoaderMessage('There is no playlist neither a country fallback playlist assigned to this device! \n Please go to the Retail Content Manager and select a playlist.');
      } else if (this.playlist.items.length === 0) {
        log(' >> PLAYLISTS HAVE NO ITEMS');
        displayLoaderMessage('Assigned playlists / country fallback playlist do not contain displayable media! \n Please go to the Retail Content Manager and adjust the playlist.');
      } else {

        log(` >> Playing ${currentItem.type}, position: ${currentItem.position + 1}, duration: ${currentItem.timer} Sec`);

        const assetType = {
          image: (): void => this.renderImage(currentItem),
          video: (): void => this.renderVideo(currentItem),
          iframe: (): void => this.renderWebContent(currentItem)
        };
    
        assetType[currentItem.type] && assetType[currentItem.type]();

        setLog('playerCurrentAssetID', currentItem.id);
    
        if (this.playerStatus !== 'active') {
          this.playerStatus = 'active';
          hideLoader();
          setLog('playerStatus', this.playerStatus);
        }
      }
    }
  };

  renderImage = (item: IPlaylistItemSanitized): void => {
    this.videoElement.removeEventListener('ended', this.goToNextAsset);

    this.imageElement.src = item.blob || item.source;

    this.imageElement.addEventListener('load', () => {
      const elementRatio = this.imageElement.naturalWidth / this.imageElement.naturalHeight;
      if (elementRatio > 1) {
        this.imageElement.classList.add('bigger-width-element');
        this.imageElement.classList.remove('bigger-height-element');
      } else {
        this.imageElement.classList.add('bigger-height-element');
        this.imageElement.classList.remove('bigger-width-element');
      }  
    });
    /**
     * Override images are displayed indefinitely.
     * Only way to escape those, is to use control skip via PATCH /digital-content/devices_DEVICE ID
     */
    if (!item.isOverride) {
      this.imageTimeout = setTimeout(() => this.goToNextAsset(), item.timer * 1000);
    }

    toggleElement(this.imageElement, true, false);
    toggleElement(this.videoElement, false, false);
  };

  renderVideo = (item: IPlaylistItemSanitized): void => {
    this.videoElement.addEventListener('ended', this.goToNextAsset);
    this.videoSource.setAttribute('src', item.blob || item.source);
    this.videoElement.load();
    this.videoElement.addEventListener('loadedmetadata', () => {
      const elementRatio = this.videoElement.videoWidth / this.videoElement.videoHeight;
      if (elementRatio > 1) {
        this.videoElement.classList.add('bigger-width-element');
        this.videoElement.classList.remove('bigger-height-element');
      } else {
        this.videoElement.classList.add('bigger-height-element');
        this.videoElement.classList.remove('bigger-width-element');
      }  
    });

    this.videoElement.play();

    toggleElement(this.videoElement, true, false);
    toggleElement(this.imageElement, false, false);
  };

  renderWebContent = (item: IPlaylistItemSanitized): void => {
    /**
     * iframe elements can't be initiated with empty src.
     * That is why we have to create it here.
     */
    this.videoElement.removeEventListener('ended', this.goToNextAsset);

    if (!this.iframeElement) {
      this.iframeElement = document.createElement('iframe');
      this.iframeElement.classList.add('dsp-player__iframe');
      this.iframeElement.setAttribute('width', '100%');
      this.iframeElement.setAttribute('height', '100%');
      this.playerElement.appendChild(this.iframeElement);
    }

    this.iframeElement.setAttribute('src', item.blob ||item.source);

    toggleElement(this.iframeElement, true, false);
    toggleElement(this.videoElement, false, false);
    toggleElement(this.imageElement, false, false);
  };

  goToNextAsset = (): void => {
    if (this.overrideAsset) {
      this.resetPlayer();
      return;
    }

    if (this.currentIndex === this.playlist.items.length - 1) {
      this.currentIndex = 0;
      this.playerTimesPlayed += 1;
      setLog('playerTimesPlayed', this.playerTimesPlayed);
      this._paq.push(['deleteCustomVariable', 1, 'visit']);
      this._paq.push([
        'setCustomVariable',
        1,
        `Times played '${this.playlist.title}'`,
        this.playerTimesPlayed,
        'visit'
      ]);

      this._paq.push(['trackPageView', `Playlist "${this.playlist.title}" recursion`]);

      // this._paq.push(['trackEvent', 'Times played', this.playerTimesPlayed]);

      setStorageLog();
    } else {
      this.currentIndex = this.currentIndex + 1;
    }

    setTimeout(() => this.renderAsset(), 1000);
  };

  resetPlayer = (): void => {
    if (this.imageTimeout) {
      clearTimeout(this.imageTimeout);
    }

    this.overrideAsset = null;
    this.currentIndex = 0;

    if (this.iframeElement) {
      toggleElement(this.iframeElement, false, false);
    }

    hideLoaderMessage();
    this.renderAsset();
  };

  pause = (): void => {
    this.videoElement.pause();
  };

  play = (): void => {
    this.videoElement.play();
  };
}
