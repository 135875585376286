import axiosInstance from '~/axiosInstance';

import {
  ENDPOINTS,
  RETRY_TIMEOUT_DURATIONS,
  RETRY_LIMIT,
  RETRY_TIMEOUT_MAX_DURATION,
  RETRY_TIMEOUT_INITIAL_DURATION
} from '~/config';
import { setLog, log, LogType } from '../controllers/logs';

let retryCount = 0;
let retryTimeout = RETRY_TIMEOUT_INITIAL_DURATION;

const retry = () => {
  log(' >> AUTHENTICATION FAILED: API DOWN');

  retryTimeout =
    retryCount >= RETRY_LIMIT ? RETRY_TIMEOUT_MAX_DURATION : RETRY_TIMEOUT_DURATIONS[retryCount];

  log(`GET PLAYLIST FAILED: Retry attempt ${retryCount}`);
  if (retryTimeout > 60 * 1000) {
    log(`GET PLAYLIST FAILED: Retrying in ${retryTimeout / (60 * 1000)} minutes`);
  } else {
    log(`GET PLAYLIST FAILED: Retrying in ${retryTimeout / 1000} seconds`);
  }

  setTimeout(() => {
    retryCount++;
    getToken().then(() => (retryCount = 0));
  }, retryTimeout);
};

export const getPin = async (): Promise<string> => {
  log(' >> PIN REQUEST');

  try {
    axiosInstance.defaults.headers['Authorization'] = '';
    const response = await axiosInstance.post(ENDPOINTS.PIN, {
      deviceNumber: localStorage.getItem('$log_deviceID')
    });
    log(' >> PIN REQUEST SUCCESS', LogType.Success);
    return response.data.pin.toString();
  } catch (error) {
    log(' >> PIN REQUEST ERROR', LogType.Error);
    throw error;
  }
};

export const getToken = async (): Promise<void | string> => {
  log(' >> AUTHENTICATE');
  try {
    const response = await axiosInstance.post(ENDPOINTS.AUTHENTICATE, {
      deviceNumber: localStorage.getItem('$log_deviceID')
    });
    const apiKey = response.data.token;
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${apiKey}`;
    log(`>> AUTHENTICATION SUCCESSFUL: ${localStorage.getItem('$log_deviceID')}`);

    return apiKey;
  } catch (error) {
    if (error.response.status === 401) {
      log(' >> AUTHENTICATION FAILED: DEVICE NOT FOUND');
      axiosInstance.defaults.headers['Authorization'] = `Basic ${window.btoa(
        `pinner:${ENDPOINTS.apiSecret}`
      )}`;
      axiosInstance.defaults.headers['Accept'] = 'application/json';
      axiosInstance.defaults.headers['Content-Type'] = 'application/json';
      return null;
    }
    log(' >> AUTHENTICATE ERROR');
    log(' >> TOKEN ERROR: ', error.response.message);
    return retry();
  }
};

export const storeClientName = (clientName: string): void => {
  setLog('clientName', clientName);
};
