import { VERSION } from './config';
// Services
import { getPin, getToken } from '~/services/registration';
import { initializeSocketsConnection } from '~/services/sockets';

// Controllers
import { displayLoaderMessage } from '~/controllers/loader';
import { renderPin, showPin } from '~/controllers/pinPair';
import Player from '~/controllers/player';

import { DEVICE_ID } from '~/config';
import { setDeviceID } from '~/services/device';
import { getSerialNumber } from './services/device';
import { log, setLog } from './controllers/logs';
import { showLoader } from './controllers/loader';

const chrome = window.chrome;

const init = async (): Promise<void> => {
  document.querySelector('.dsp-log-container__version').textContent = 'v' + VERSION;
  log(` >> APPLICATION VERSION: ${VERSION}`);
  if (chrome) {
    log(' >> i am chrome');
    if (chrome.runtime) {
      log(' >> chrome runtime exists');
    }
  }

  if (chrome && chrome.power) {
    chrome.power.requestKeepAwake('system');
    chrome.power.requestKeepAwake('display');
  }

  showLoader();

  const deviceSerialNumber = await getSerialNumber();

  log(` >> SERIAL NUMBER: ${deviceSerialNumber}`);
  setLog('deviceID', deviceSerialNumber);

  await setDeviceID(deviceSerialNumber);

  if (!DEVICE_ID) {
    displayLoaderMessage('Device could not be identified');
    return;
  }

  const playerInstance = new Player();
  const apiToken = await getToken();

  initializeSocketsConnection(playerInstance);

  if (apiToken === null) {
    const pin = await getPin();
    renderPin(pin);
    showPin();
    return;
  }

  await playerInstance.init();
};

// Initial load
window.addEventListener('load', () => {
  if (window.chrome) {
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        document.documentElement.requestFullscreen();
      }
    });

    document.addEventListener('dblclick', () => {
      document.documentElement.requestFullscreen();
    });
  }
  init();
});
