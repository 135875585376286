// Configuration variables
export const __DEV__ = process.env.NODE_ENV === 'development';

export const MOCK_DEVICE_ID = '5CD115CJTW';

const ENVIRONMENTS = {
  TUI: {
    API_SERVER: 'https://api.audi-vmrs.exozet.com',
    WEB_SOCKET: 'ws://tui-push.audi-vmrs.exozet.com/ws',
    CHANNEL_SLUG: {
      audi: 'eab32c52-42e6-47ff-b422-8ece4444ec06',
      cupra: 'digital-signage-cupra',
      volkswagen: 'digital-retail-vw'
    }
  },
  STAGE: {
    API_SERVER: 'https://api-stage.audi-vmrs.exozet.com',
    WEB_SOCKET: 'wss://push-stage.audi-vmrs.exozet.com/ws',
    CHANNEL_SLUG: {
      audi: '14e645c4-1896-425b-8c60-59e94d53beaa',
      cupra: 'digital-signage-cupra',
      volkswagen: 'digital-retail-vw'
    }
  },
  LIVE: {
    API_SERVER: 'https://api.content.audi/',
    WEB_SOCKET: 'wss://push.audi-vmrs.exozet.com/ws/',
    CHANNEL_SLUG: {
      audi: '121973e9-35ec-4be2-9ab0-bc6df6263ee2',
      cupra: 'digital-signage-cupra',
      volkswagen: 'digital-retail-vw'
    }
  }
};

export const VERSION = process.env.VERSION;

export const CURRENT_ENVIRONMENT = ENVIRONMENTS[process.env.APP_ENV] || ENVIRONMENTS.STAGE;

export let DEVICE_ID = null;

export let ENDPOINTS = null;

export const UPDATE_CONFIG_VARIABLES = (key: string | void): void => {
  DEVICE_ID = key;
  ENDPOINTS = {
    AUTHENTICATE: `${CURRENT_ENVIRONMENT.API_SERVER}/devices/${DEVICE_ID}/authenticate`,
    PIN: `${CURRENT_ENVIRONMENT.API_SERVER}/devices/${DEVICE_ID}/pin`,
    PLAYLIST: `${CURRENT_ENVIRONMENT.API_SERVER}/digital-retail/devices/${DEVICE_ID}`,
    FALLBACK_PLAYLIST: `${CURRENT_ENVIRONMENT.API_SERVER}/digital-retail/fallback-playlists`,
    ASSET_OVERRIDE: `${CURRENT_ENVIRONMENT.API_SERVER}/digital-retail/asset-overrides`,
    WEB_SOCKET: `${CURRENT_ENVIRONMENT.WEB_SOCKET}/${DEVICE_ID}`
  };
};

export const VIDEO_FORMATS = {
  HD: 'mtt',
  FHD: 'fullhd_1080p_8000',
  UHD: 'uhd264'
};

export const PLAYLIST_DEFAULTS = {
  // UPDATE_INTERVAL: 15 * 1000
  UPDATE_INTERVAL: 30 * 60000
};

export const OPERATING_SYSTEMS = {
  WINDOWS: 'WIN',
  CHROME: 'CHR',
  MAC: 'MAC'
};

export enum BRANDS {
  'AUDI' = 'AUDI',
  'CUPRA' = 'CUPRA',
  'VOLKSWAGEN' = 'VOLKSWAGEN'
}
