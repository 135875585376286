import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json'
  }
});

export default axiosInstance;
