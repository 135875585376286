import axiosInstance from '~/axiosInstance';

import { ENDPOINTS } from '~/config';
import { setLog, log, LogType } from '../controllers/logs';

export const getPin = async (): Promise<string> => {
  log('>> PIN REQUEST');
  let secret: string;

  try {
    if (navigator.managed) {
      log('GET MANAGED CONFIGURATIONS');
      const managedSecret = await navigator.managed.getManagedConfiguration(['secret']);
      if (typeof managedSecret.secret === 'string') {
        secret = managedSecret.secret;
      }
    }

    if (!secret) {
      throw 'Access denied';
    }

    try {
      const response = await axiosInstance.post(ENDPOINTS.PIN, {
        secret
      });
      log('>> PIN REQUEST SUCCESS', LogType.Success);
      return response.data.pin.toString();
    } catch (error) {
      log('>> PIN REQUEST ERROR', LogType.Error);
      throw error;
    }
  } catch (error) {
    log('ACCESS DENIED', LogType.Error);
    return;
  }
};

export const getToken = async (): Promise<string> => {
  log('>> AUTHENTICATE');
  try {
    const response = await axiosInstance.post(ENDPOINTS.AUTHENTICATE, {});
    const apiKey = response.data.data.api_key;

    axiosInstance.defaults.headers['Authorization'] = `Bearer ${apiKey}`;
    log('>> AUTHENTICATE SUCCESS');
    return apiKey ? apiKey : null;
  } catch (error) {
    log('>> AUTHENTICATE ERROR');
    console.log('TOKEN ERROR: ', error);
  }
};

export const storeClientName = (clientName: string): void => {
  setLog('clientName', clientName);
};
