import { UPDATE_CONFIG_VARIABLES } from '~/config';
import { displayLoaderMessage } from '~/controllers/loader';
import { getOS } from '../utils';
import { log, LogType } from '../controllers/logs';
import { MOCK_DEVICE_ID } from '../config';

declare global {
  interface Navigator {
    managed: {
      getSerialNumber: () => Promise<string>;
      getManagedConfiguration: (args: string[]) => Promise<{ [x: string]: string | number }>;
    };
  }
}

const getSerialFromManagedProperty = (): Promise<string> => {
  log('READING SERIAL NUMBER FROM THE DEVICE');
  return new Promise((resolve, reject) => {
    navigator.managed
      .getSerialNumber()
      .then(serialNumber => {
        log(`Received serial number: ${serialNumber}`);
        displayLoaderMessage('Device serial number' + serialNumber);
        resolve(serialNumber);
      })
      .catch(e => {
        console.log(e);
        reject('Error while reading serial number.');
      });
  });
};

export const getSerialNumber = async (): Promise<string> => {
  log('GET SERIAL NUMBER REQUEST');

  const urlParams = new URLSearchParams(window.location.search);
  const deviceIdParam = urlParams.get('deviceId');

  if (deviceIdParam) {
    log('SERIAL NUMBER READ FROM URL');
    return deviceIdParam;
  }

  if (navigator && navigator.managed && navigator.managed.getSerialNumber) {
    return getSerialFromManagedProperty();
  } else {
    // TODO: could add mock value here
    // log('NO ACCESS');
    log('>> USING MOCK DEVICE');
    return MOCK_DEVICE_ID;
    return;
  }
};

export const setDeviceID = async (serialNumber: string): Promise<string | void> => {
  const currentOS = getOS();

  if (!currentOS) {
    return;
  }

  UPDATE_CONFIG_VARIABLES(serialNumber);
};
