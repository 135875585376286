import axiosInstance from '~/axiosInstance';

import { BRANDS, ENDPOINTS, RETRY_TIMEOUT_DURATIONS, RETRY_LIMIT, RETRY_TIMEOUT_MAX_DURATION } from '~/config';
import { IPlaylistSanitized } from '~/interfaces/playlists';
import { getToken } from '~/services/registration';

import { setLog } from '~/controllers/logs';
import { log } from '../controllers/logs';
import {
  ResourceResponse,
  DeviceResource,
  IPlaylistItem
} from '../interfaces/api';

let retryCount = 0;
let retryTimeout;

/*
  Each playlist item (asset) comes from VMS with multiple sources, each with a different quality.
  Chooses the correct source based on screen resolution and asset type.
*/
// const getAssetSource = (item: IPlaylistItem): string => {
//   const assetType = {
//     image: (): string => {
//       return item.thumbnails.reference.url;
//     },
//     video: (): string => {
//       const { innerWidth } = window;
//       const { UHD, FHD, HD } = VIDEO_FORMATS;
//       const currentFormat = innerWidth > 1920 ? UHD : innerWidth > 1280 ? FHD : HD;
//       return item.sources[currentFormat].url;
//     },
//     default: null
//   };

//   return assetType[item.type] ? assetType[item.type]() : assetType['default'];
// };

/*
  Sanitizes the playlist's items, keeping only information which is needed for the player:
    id        - unique identifier
    type      - image | video
    position  - playlist index
    source    - Akamai URL of the asset
    timer     - time to display (in seconds)
    usageTo     - expiration date
*/
const selectVideoSource = (sourceFormats) => {
  return  sourceFormats.video_2160p ||
    sourceFormats.video_1440p ||
    sourceFormats.video_1080p ||
    sourceFormats.video_0720p ||
    sourceFormats.video_0500p ||
    sourceFormats.video_0396p ||
    sourceFormats.video_0270p ||
    sourceFormats.video_0180p;
};

const selectImageSource= (sourceFormats) => {
  return sourceFormats.web_2160p ||
    sourceFormats.web_1080p ||
    sourceFormats.thumb_0720p ||
    sourceFormats.thumb_0500p ||
    sourceFormats.thumb_0396p ||
    sourceFormats.thumb_0270p;
};

const sanitizeItems = (items: Array<IPlaylistItem> | any): any => {
  const sanitizedItems = [];

  items.forEach(item => {
    const {
      sourceFormats, 
      title,
      duration,
      position,
      uuid,
      type,
      usageTo
    } = item;


    if (sourceFormats) {
      sanitizedItems.push({
        id: uuid,
        type: type,
        position: position,
        source: type === 'image' ? selectImageSource(sourceFormats) :selectVideoSource(sourceFormats),
        timer: duration || 15,
        title: title,
        usageTo: usageTo
      });
    }
  });

  return sanitizedItems;
};

const getBrand = (brand: string): string => {
  if (!brand) {
    return BRANDS.AUDI;
  }

  const brandLetter = brand.charAt(brand.length - 1);
  setLog('deviceBrand', brandLetter);

  switch (brandLetter) {
    case 'A':
      return BRANDS.AUDI;
    case 'S':
      return BRANDS.CUPRA;
    case 'V':
      return BRANDS.VOLKSWAGEN;
    default:
      return BRANDS.AUDI;
  }
};
/*
  Playlist's items do not come in the correct order but each one has its correct position stored.
  Compares the items based on their 'position' value and return the array sorted.
*/
const sortItems = (a: IPlaylistItem, b: IPlaylistItem): number =>
  a.position > b.position ? 1 : b.position > a.position ? -1 : 0;

const reIndex = items => {
  const nextItems = [...items];
  return nextItems.map((item, index) => ({
    ...item,
    position: index
  }));
};

const checkExpiredAsset = item => {
  const today = new Date();
  const expirationDate = new Date(item.usageTo);
  if (expirationDate < today) {
    return false;
  } else {
    return true;
  }
};

const getAllItems = async (playlists: any[]) => {
  const playlistsItemsPromises =[];
  let playlistsItems = [];

  //getting playlists items

  playlists.forEach(playlist => playlistsItemsPromises.push(axiosInstance.get(ENDPOINTS.API_SERVER + playlist.contentPlaylist + '/items')));
  
  await Promise.all(playlistsItemsPromises).then(res => res.forEach(playlist => playlist.data['hydra:member'].forEach(playlistItems => playlistsItems.push(playlistItems))));

  playlistsItems = playlistsItems.filter(item => !item.usageTo || checkExpiredAsset(item));

  return reIndex(playlistsItems);
};
/*
  Gets the playlist from VMS, sanitizes, sorts and returns for later usage.
*/
export const getPlaylist = async (): Promise<IPlaylistSanitized | any> => {
  log(' >> GET PLAYLIST');

  try {
    const response = await axiosInstance.get<ResourceResponse<DeviceResource>>(ENDPOINTS.PLAYLIST);

    const { playlists, dealership, brand } = response.data;

    setLog('dealership', dealership);
    log(' >> GET PLAYLIST SUCCESS');
    if(playlists.length === 0) {
      return null;
    } else { 
      return {
        items: sanitizeItems(await getAllItems(playlists)),
        brand: getBrand(brand),
        title: playlists.map(playlist => playlist.title).join(', '),
        countryIso3: dealership.split('/').pop().substring(0,3)
      };
    }
  } catch (error) {
    log(' >> GET PLAYLIST ERROR');
    if ([401, 403].includes(error.response.status)) {
      log(' >> unautherized try to get playlist');
      return getToken();
    }
    if (
      !error.response ||
      !error.response.playlists ||
      `${error.response.status}`.charAt(0) === "5"
    ){
      log(' >> response is null, or connection was interrupted, or CORS response throws null response.');
      return retry();
    }
  }
};

const retry = () =>{
  log(' >> GET PLAYLIST FAILED: Internet connection lost or API is down, retry()');

  retryTimeout = retryCount >= RETRY_LIMIT
    ? RETRY_TIMEOUT_MAX_DURATION
    : RETRY_TIMEOUT_DURATIONS[retryCount];

  log(`GET PLAYLIST FAILED: Retry attempt ${retryCount}`);
  
  if (retryTimeout > 60 * 1000) {
    log(`GET PLAYLIST FAILED: Retrying in ${retryTimeout / (60 * 1000)} minutes`);
  } else {
    log(`GET PLAYLIST FAILED: Retrying in ${retryTimeout / 1000} seconds`);
  }

  setTimeout(() => {
    retryCount++;
    getPlaylist().then(() =>  retryCount = 0);
  }, retryTimeout);
}