import { ENDPOINTS, WHITELISTED_BASE_URLS } from '~/config';

// Services
import { getPin, getToken } from '~/services/registration';

// Controllers
import { renderPin, hidePin } from '~/controllers/pinPair';
import { hideLoader } from '~/controllers/loader';
import { IPlayer } from '~/controllers/player';
import { log } from '../controllers/logs';
import { toggleElement } from '../utils';
import { showSplash } from '~/controllers/splash';

type AssetOverrideWebSocketPayload = {
  uuid: string;
  type: 'video' | 'image' | 'webview';
  url: string;
  state: 'new' | 'in_progress' | 'done';
};

enum DebugMessages {
  Enable = 'enable',
  Disable = 'disable'
}

type DebugMessagePayload = {
  action: string;
  type: DebugMessages;
};

const handleDevicePairedMessage = (playerInstance: IPlayer): void => {
  setTimeout((): void => {
    getToken().then((): void => {
      hidePin();
      hideLoader();
      playerInstance.init();
    });
  }, 3000);
};

const handlePinExpiredMessage = (): void => {
  getPin().then(pin => {
    if (!pin) {
      return;
    }

    renderPin(pin);
  });
};

const handleAssetOverrideMessage = (
  payload: AssetOverrideWebSocketPayload,
  playerInstance: IPlayer
): void => {
  const { uuid, type, url, state } = payload;
  if(state === 'new') {
    if (WHITELISTED_BASE_URLS.some(whitelistBaseURL => url.includes(whitelistBaseURL))) {
      log(` >> OVERRIDE URL IS WHITELISTED, OVERRIDE IN PROGRESS | ID: ${uuid} | URL: ${url}`);
      window._paq.push([
        'trackEvent',
        'Asset Override ',
        `UUID: ${uuid}; DealershipID: ${localStorage.getItem('dealership')}`
      ]);

      const item = {
        id: uuid as any,
        position: 0,
        timer: -1,
        type: type === 'webview' ? 'iframe' : type,
        source: url,
        isOverride: true,
        usageTo: null
      };
      showSplash();
      setTimeout(() => playerInstance.renderAsset(item), 1000);
    } else {
        log(` >> OVERRIDE URL IS NOT WHITELISTED, OVERRIDE PROCESS IS IGNORED | ID: ${uuid} | URL: ${url}`);
    }
  }
  if(state === 'done') {
    playerInstance.renderAsset();
  }
};

const handleDebugRequest = (payload: DebugMessagePayload) => {
  const logListElement = document.querySelector('.dsp-log-container');
  switch (payload.type) {
    case DebugMessages.Enable:
      toggleElement(logListElement, true, false);
      break;
    case DebugMessages.Disable:
      toggleElement(logListElement, false, false);
      break;
    default:
      break;
  }
};

export const initializeSocketsConnection = (playerInstance: IPlayer): void => {
  const socket = new WebSocket(ENDPOINTS.WEB_SOCKET);

  const onMessageReceived = (event): void => {
    const eventData = JSON.parse(event.data);

    log(` >> WEB SOCKET MESSAGE RECEIVED | ACTION: ${eventData.action}`);

    const actionType = {
      'device-paired': (): void => handleDevicePairedMessage(playerInstance),
      'pin-expired': (): void => handlePinExpiredMessage(),
      debug: (): void => handleDebugRequest(eventData),
      override: (): void => handleAssetOverrideMessage(eventData, playerInstance),
      play: (): void => playerInstance.play(),
      pause: (): void => playerInstance.pause(),
      skip: (): void => playerInstance.resetPlayer(),
      default: (): void => null
    };

    if (actionType[eventData.action]) {
      actionType[eventData.action]();
    } else {
      actionType['default']();
    }
  };

  // Open connection
  socket.addEventListener('open', () => log(' >> WS Connection is open'));

  // Incoming message
  socket.addEventListener('message', onMessageReceived);
};
