import { UPDATE_CONFIG_VARIABLES } from '~/config';
import { getOS } from '../utils';
import { log, LogType} from '../controllers/logs';
import { MOCK_DEVICE_ID } from '../config';

declare global {
  interface Navigator {
    managed: {
      getSerialNumber: () => Promise<string>;
      getManagedConfiguration: (args: string[]) => Promise<{ [x: string]: string | number }>;
    };
  }
}

const getSerialFromManagedProperty = (): Promise<string> => {
  log(' >> READING SERIAL NUMBER FROM THE DEVICE');
  return new Promise((resolve, reject) => {
    navigator.managed
      .getSerialNumber()
      .then(serialNumber => {
        resolve(serialNumber);
      })
      .catch(e => {
        reject('Error while reading serial number from the device.');
      });
  });
};

export const getSerialNumber = async (): Promise<string> => {
  let deviceNumber;
  log(' >> GET SERIAL NUMBER REQUEST');
    
  await getSerialFromManagedProperty()
  .then(serialNumber => {
    log(` >> Received device serial number: ${serialNumber}`, LogType.Success);
    deviceNumber=serialNumber;
  })
  .catch(error => {
    log(' >> Error while reading serial number from the device.', LogType.Error);
    log(' >> USING MOCK DEVICE');
    deviceNumber=MOCK_DEVICE_ID;
  });

  return deviceNumber
};

export const setDeviceID = async (serialNumber: string): Promise<string | void> => {
  const currentOS = getOS();

  if (!currentOS) {
    return;
  }

  UPDATE_CONFIG_VARIABLES(serialNumber);
};
