export const MOCK_DEVICE_ID = '5CD115CJTW'; // mhd home device

const ENVIRONMENTS = {
  DEV: {
    API_SERVER: 'https://api.dev.newretail.avp.tech',
    WEB_SOCKET: 'wss://push.dev.newretail.avp.tech/ws',
    apiSecret: 'MkfAGlheoLyon96xcVecRNjl7PUnYlqv',
    SLUG: {
      audi: 'a-digital-retail',
      cupra: 's-digital-retail',
      volkswagen: 'v-digital-retail',
    }
  },
  INT: {
    API_SERVER: 'https://api.int.newretail.avp.tech',
    WEB_SOCKET: 'wss://push.int.newretail.avp.tech/ws',
    apiSecret: 'MkfAGlheoLyon96xcVecRNjl7PUnYlqv',
    SLUG: {
      audi: 'a-digital-retail',
      cupra: 's-digital-retail',
      volkswagen: 'v-digital-retail',
    }
  },
  PROD: {
    API_SERVER: 'https://api.prod.newretail.avp.tech',
    WEB_SOCKET: 'wss://push.prod.newretail.avp.tech/ws',
    apiSecret: 'EuKKeqAm1flK8o6Xz5duKFBuFzQMnht3',
    SLUG: {
      audi: 'a-digital-retail',
      cupra: 's-digital-retail',
      volkswagen: 'v-digital-retail',
    }
  }
};

export const VERSION = process.env.VERSION;

export const CURRENT_ENVIRONMENT = ENVIRONMENTS[process.env.APP_ENV];

export let DEVICE_ID = null;

export let ENDPOINTS = null;

export const UPDATE_CONFIG_VARIABLES = (key: string | void): void => {
  DEVICE_ID = key;
  ENDPOINTS = {
    AUTHENTICATE: `${CURRENT_ENVIRONMENT.API_SERVER}/authentication/device-token`,
    PIN: `${CURRENT_ENVIRONMENT.API_SERVER}/devices/pins`,
    PLAYLIST: `${CURRENT_ENVIRONMENT.API_SERVER}/devices/${DEVICE_ID}`,
    FALLBACK_PLAYLIST: `${CURRENT_ENVIRONMENT.API_SERVER}/digital-retail/fallback-playlists`,
    ASSET_OVERRIDE: `${CURRENT_ENVIRONMENT.API_SERVER}/digital-retail/asset-overrides`,
    WEB_SOCKET: `${CURRENT_ENVIRONMENT.WEB_SOCKET}/${DEVICE_ID}`,
    API_SERVER: `${CURRENT_ENVIRONMENT.API_SERVER}`,
  };
};

export const VIDEO_FORMATS = {
  HD: 'mtt',
  FHD: 'fullhd_1080p_8000',
  UHD: 'uhd264'
};

export const PLAYLIST_DEFAULTS = {
  UPDATE_INTERVAL: 30 * 60000
};

export const OPERATING_SYSTEMS = {
  WINDOWS: 'WIN',
  CHROME: 'CHR',
  MAC: 'MAC'
};

export enum BRANDS {
  'AUDI' = 'AUDI',
  'CUPRA' = 'CUPRA',
  'VOLKSWAGEN' = 'VOLKSWAGEN'
}

export const RETRY_LIMIT = 4;

export const RETRY_TIMEOUT_MAX_DURATION = 10 * 60 * 1000;

export const RETRY_TIMEOUT_INITIAL_DURATION = 1000;

export const RETRY_TIMEOUT_DURATIONS= [
  1 * 1000,
  45 * 1000,
  2 * 60 * 1000,
  5 * 60 * 1000,
] 

export const WHITELISTED_BASE_URLS = [
  'adobeaemcloud.com',
  'https://mediaservice.audi.com',
  'https://render.seat.fi',
  'https://dcc-app.cocos-us-int.webapps.audi.io',
  'https://dcc-app.cocos-ap-int.webapps.audi.io',
  'https://dcc-app.cocos-eu-int.webapps.audi.io',
  'https://pre-dcc-de-eva.audi.de',
  'https://pre-dcc-drm-eu.audi.de',
  'https://pre-dcc-uk.audi.de',
  'https://dcc-drm-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-fr-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-uk-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-nl-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-se-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-it-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-es-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-de-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-dk-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-fi-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-ie-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-ars-int-dpu.cocos-eu-int.webapps.audi.io',
  'https://dcc-drm-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-fr-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-uk-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-nl-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-se-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-it-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-es-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-de-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-dk-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-fi-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-ie-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-ars-tui-dpu.cocos-eu-tui.webapps.audi.io',
  'https://dcc-app.cocos-us-prd.webapps.audi.io',
  'https://dcc-app.cocos-ap-prd.webapps.audi.io',
  'https://dcc-app.cocos-eu-prd.webapps.audi.io',
  'https://dcc-de-eva.audi.de',
  'https://dcc-drm-eu.audi.de',
  'https://dcc-uk.audi.de',
  'https://dcc-drm-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-fr-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-uk-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-nl-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-se-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-it-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-es-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-de-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-dk-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-fi-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-ie-prd-dpu.cocos-eu-prd.webapps.audi.io',
  'https://dcc-ars-prd-dpu.cocos-eu-prd.webapps.audi.io'
]